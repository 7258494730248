import React, { useContext } from "react";
import { useLocalization } from "../../../../../context/LocalizeContextProvider";
import { Link } from "react-router-dom";
import Table from "../../../../components/Table";
// import FilterPatient from "../../../../components/FilterPatient";
import Button from "../../../../components/Button";
import AsyncSelect from "../../../../components/AsyncSelect";
import InputField from "../../../../components/InputField";
import { ThemeContext } from "../../../../../context/ThemeContext";
import { optionsFilterDuration } from "../../../../../utils/constants";
import cx from "classnames";

export default function ListHistoryVisitComponent({
  dataHistory,
  isLoading = false,
  totalPage = 0,
  totalEntries = 0,
  handlePagination,
  activePage,
  filterHistory,
  handleChangeFilter,
  handleClickFilter,
  handleClearFilter,
  handleExportHistory,
  isLoadingExport = false,
  handleChangeSizePerPage,
  dataDoctors = [],
  isLoadingDoctor = false,
  isDoctor = false,
}) {
  const localize = useLocalization();
  const { windowWidth } = useContext(ThemeContext);
  const isMobile = windowWidth <= 768;
  const formatInputDate =
    optionsFilterDuration.find(
      (item) => item.value === filterHistory.type?.value
    )?.format || "DD-MM-YYYY";

  const columns = [
    { label: localize.getText("noRm") },
    { label: localize.getText("doctor") },
    { label: localize.getText("name") },
    { label: localize.getText("dateOfBirth") },
    { label: localize.getText("gender") },
    { label: localize.getText("treatment") },
    {
      label: localize.getText("action"),
      className: "text-center",
      style: { width: "200px" },
    },
  ];

  return (
    <div className="card">
      <div className="card-body">
        <div className="d-flex flex-column gap-2 mb-2">
          <div
            className={cx(
              !isMobile && "gap-4",
              "d-flex flex-row flex-wrap w-100 justify-content-center"
            )}
          >
            <AsyncSelect
              label={localize.getText("doctor")}
              placeholder={localize.getText("placeholderField", {
                fieldName: localize.getText("doctor"),
              })}
              options={dataDoctors}
              fullWidth={isMobile}
              height={38}
              isBordered
              isClearable
              labelKey="name"
              valueKey="id"
              isLoading={isLoadingDoctor}
              onChange={(value) => handleChangeFilter("doctor_id", value)}
              value={filterHistory.doctor_id}
              disabled={!!isDoctor}
            />
            <AsyncSelect
              label={localize.getText("searchBy")}
              placeholder="Pilih kategori"
              options={optionsFilterDuration}
              value={filterHistory.type}
              onChange={(value) => handleChangeFilter("type", value)}
              fullWidth={isMobile}
              height={38}
              isBordered
            />
            <InputField
              type="date"
              views={[filterHistory.type?.value || "day"]}
              format={formatInputDate}
              label={localize.getText("from")}
              onChange={(value) => handleChangeFilter("from", value)}
              value={filterHistory.from || null}
              fullWidth={isMobile}
              disableHighlightToday
              isBorder
              classNameInput="date-picker-search"
            />
            <InputField
              type="date"
              views={[filterHistory.type?.value || "day"]}
              format={formatInputDate}
              label={localize.getText("to")}
              value={filterHistory.to || null}
              onChange={(value) => handleChangeFilter("to", value)}
              shouldDisableYear={(year) => {
                return year.isBefore(filterHistory.from);
              }}
              shouldDisableMonth={(month) => {
                return month.isBefore(filterHistory.from);
              }}
              shouldDisableDate={(date) => {
                return date.isBefore(filterHistory.from);
              }}
              fullWidth={isMobile}
              disabled={!filterHistory.from}
              disableHighlightToday
              disablePast
              isEndField
              isBorder
              classNameInput="date-picker-search"
            />
          </div>
          <div className="d-flex gap-2 align-items-center justify-content-center">
            <Button
              label={localize.getText("search")}
              onClick={handleClickFilter}
            />
            <Button
              leftIcon={<i className="far fa-file-excel"></i>}
              label="Export"
              variant="info"
              onClick={handleExportHistory}
              loading={isLoadingExport}
            />
            <Button
              label={localize.getText("reset")}
              variant="danger"
              onClick={handleClearFilter}
            />
          </div>
        </div>
        <Table
          columns={columns}
          loading={isLoading}
          activePage={activePage}
          totalPage={totalPage}
          totalShow={dataHistory.length}
          totalEntries={totalEntries}
          handlePagination={handlePagination}
          sizePerPage={filterHistory.size}
          handleSizePerPage={handleChangeSizePerPage}
        >
          {dataHistory.map((item, index) => (
            <tr key={index}>
              <td className="sorting_1">{item?.medicalRecordNumber || "-"}</td>
              <td>{item?.visit?.doctorName}</td>
              <td>{item.name}</td>
              <td>{item.dob}</td>
              <td>{item.gender}</td>
              <td>{item.visit.treatment || "-"}</td>
              <td>
                <div className="d-flex justify-content-center gap-3">
                  <Link
                    to={{
                      pathname: "/active-visit/assesment",
                      search: `?id=${item.activeVisitId}&patientId=${item.id}`,
                      state: { isView: true },
                    }}
                  >
                    <Button
                      variant="success"
                      tooltip={localize.getText("assesment")}
                    >
                      <i class="fas fa-file-alt"></i>
                    </Button>
                  </Link>
                </div>
              </td>
            </tr>
          ))}
        </Table>
      </div>
    </div>
  );
}
