import React from "react";
import ListHistoryVisitComponent from "../../components/ListHistoryVisit";
import useHistoryVisit from "../../../../../hooks/useHistoryVisit";
import { useHome } from "../../../../../context/HomeProvider";
import { useAuth } from "../../../../../context/AuthProvider";
import { roleUser } from "../../../../../utils/constants";

export default function ListHistoryVisit() {
  const { dataDoctors, isLoadingDoctor } = useHome();
  const { role } = useAuth();
  const isDoctor =
    role === roleUser.ROLE_DOCTOR || role === roleUser.ROLE_BEAUTICIAN;

  const {
    dataHistoryVisit,
    handleChangeFilter,
    handleChangeSizePerPage,
    handleClickFilter,
    handlePagination,
    handleClearFilter,
    handleExportHistory,
    isLoading,
    totalEntries,
    totalPage,
    filterHistory,
    isLoadingExport,
  } = useHistoryVisit();

  return (
    <ListHistoryVisitComponent
      dataHistory={dataHistoryVisit}
      isLoading={isLoading}
      totalPage={totalPage}
      totalEntries={totalEntries}
      activePage={filterHistory?.page}
      handlePagination={handlePagination}
      filterHistory={filterHistory}
      handleChangeFilter={handleChangeFilter}
      handleClickFilter={handleClickFilter}
      handleClearFilter={handleClearFilter}
      handleChangeSizePerPage={handleChangeSizePerPage}
      handleExportHistory={handleExportHistory}
      isLoadingExport={isLoadingExport}
      dataDoctors={dataDoctors}
      isLoadingDoctor={isLoadingDoctor}
      isDoctor={isDoctor}
    />
  );
}
