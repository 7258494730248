import React, { useContext } from "react";
import { useLocalization } from "../../../../../context/LocalizeContextProvider";
import Table from "../../../../components/Table";
// import FilterPatient from "../../../../components/FilterPatient";
import Button from "../../../../components/Button";
import AsyncSelect from "../../../../components/AsyncSelect";
import InputField from "../../../../components/InputField";
import { ThemeContext } from "../../../../../context/ThemeContext";
import { optionsFilterDuration } from "../../../../../utils/constants";
import cx from "classnames";

export default function ListReportVisitComponent({
  dataReport,
  isLoading = false,
  totalPage = 0,
  totalEntries = 0,
  handlePagination,
  activePage,
  filterReport,
  handleChangeFilter,
  handleClickFilter,
  handleClearFilter,
  handleExportReport,
  isLoadingExport = false,
  handleChangeSizePerPage,
  dataDoctors = [],
  isLoadingDoctor = false,
  isDoctor = false,
}) {
  const localize = useLocalization();
  const { windowWidth } = useContext(ThemeContext);
  const isMobile = windowWidth <= 768;
  const formatInputDate =
    optionsFilterDuration.find(
      (item) => item.value === filterReport.type?.value
    )?.format || "DD-MM-YYYY";

  const columns = [
    { label: localize.getText("doctor") },
    { label: localize.getText("totalPatient") },
    { label: localize.getText("totalPasientMan") },
    { label: localize.getText("totalPasientWoman") },
  ];

  return (
    <div className="card">
      <div className="card-body">
        <div className="d-flex flex-column gap-2 mb-2">
          <div
            className={cx(
              !isMobile && "gap-4",
              "d-flex flex-row flex-wrap w-100 justify-content-center"
            )}
          >
            <AsyncSelect
              label={localize.getText("doctor")}
              placeholder={localize.getText("placeholderField", {
                fieldName: localize.getText("doctor"),
              })}
              options={dataDoctors}
              fullWidth={isMobile}
              height={38}
              isBordered
              isClearable
              labelKey="name"
              valueKey="id"
              isLoading={isLoadingDoctor}
              onChange={(value) => handleChangeFilter("doctor_id", value)}
              value={filterReport.doctor_id}
              disabled={!!isDoctor}
            />
            <AsyncSelect
              label={localize.getText("searchBy")}
              placeholder="Pilih kategori"
              options={optionsFilterDuration}
              value={filterReport.type}
              onChange={(value) => handleChangeFilter("type", value)}
              fullWidth={isMobile}
              height={38}
              isBordered
            />
            <InputField
              type="date"
              views={[filterReport.type?.value || "day"]}
              format={formatInputDate}
              label={localize.getText("from")}
              onChange={(value) => handleChangeFilter("from", value)}
              value={filterReport.from || null}
              fullWidth={isMobile}
              disableHighlightToday
              isBorder
              classNameInput="date-picker-search"
            />
            <InputField
              type="date"
              views={[filterReport.type?.value || "day"]}
              format={formatInputDate}
              label={localize.getText("to")}
              value={filterReport.to || null}
              onChange={(value) => handleChangeFilter("to", value)}
              shouldDisableYear={(year) => {
                return year.isBefore(filterReport.from);
              }}
              shouldDisableMonth={(month) => {
                return month.isBefore(filterReport.from);
              }}
              shouldDisableDate={(date) => {
                return date.isBefore(filterReport.from);
              }}
              fullWidth={isMobile}
              disabled={!filterReport.from}
              disableHighlightToday
              disablePast
              isEndField
              isBorder
              classNameInput="date-picker-search"
            />
          </div>
          <div className="d-flex gap-2 align-items-center justify-content-center">
            <Button
              label={localize.getText("search")}
              onClick={handleClickFilter}
            />
            <Button
              leftIcon={<i className="far fa-file-excel"></i>}
              label="Export"
              variant="info"
              onClick={handleExportReport}
              loading={isLoadingExport}
            />
            <Button
              label={localize.getText("reset")}
              variant="danger"
              onClick={handleClearFilter}
            />
          </div>
        </div>
        <Table
          columns={columns}
          loading={isLoading}
          activePage={activePage}
          totalPage={totalPage}
          totalShow={dataReport.length}
          totalEntries={totalEntries}
          handlePagination={handlePagination}
          sizePerPage={filterReport.size}
          handleSizePerPage={handleChangeSizePerPage}
        >
          {dataReport.map((item, index) => (
            <tr key={index}>
              <td>{item?.doctorName}</td>
              <td>{item.totalPatients || 0}</td>
              <td>{item.totalMalePatients || 0}</td>
              <td>{item.totalFemalePatients || 0}</td>
            </tr>
          ))}
        </Table>
      </div>
    </div>
  );
}
