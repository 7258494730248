import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { Form, Field } from "react-final-form";
import styles from "./FormPatients.module.css";
import { useLocalization } from "../../../../../context/LocalizeContextProvider";

import { Gender, TypeIdentity } from "../../../../../utils/constants";
import Button from "../../../../components/Button";
import InputField from "../../../../components/InputField";
import RadioField from "../../../../components/RadioField";
import TitlePage from "../../../../components/TitlePage";
import AsyncSelect from "../../../../components/AsyncSelect";
import moment from "moment";

export default function FormPatient({
  handleSubmitForm = () => undefined,
  isLoadingSubmit = false,
  dataForm = null,
  isEdit = false,
  isDetail = false,
}) {
  const localize = useLocalization();

  const [dataPatient, setDataPatient] = useState({
    name: null,
    phoneNumber: null,
    dateOfBirth: null,
    gender: null,
    address: null,
    remark: null,
    allergies: [],
    identificationType: "KTP",
    identificationNumber: null,
    medicalRecordNumber: null,
  });

  useEffect(() => {
    if (dataForm) {
      setDataPatient({
        name: dataForm?.name,
        phoneNumber: dataForm?.phoneNumber,
        dateOfBirth: moment(dataForm?.dob, "DD-MM-YYYY").format("YYYY-MM-DD"),
        gender: dataForm?.gender,
        address: dataForm?.address,
        remark: dataForm?.remark,
        allergies: dataForm?.allergy || [],
        identificationType: dataForm?.identificationType || "KTP",
        identificationNumber: dataForm?.identificationNumber,
        medicalRecordNumber: dataForm?.medicalRecordNumber,
      });
    }
  }, [dataForm]);

  const handleChangeForm = (name, value) => {
    setDataPatient((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <div className="card">
      <div className={classNames("card-body", styles.wrapper)}>
        <TitlePage
          text={
            isEdit
              ? localize.getText("editNewPatient")
              : isDetail
              ? localize.getText("detailPatient")
              : localize.getText("addNewPatient")
          }
          isBack
        />
        <Form
          onSubmit={handleSubmitForm}
          initialValues={dataPatient}
          validate={(values) => {
            const errors = {};
            if (!values.name) {
              errors.name = localize.getText("generalRequiredFieldValidation", {
                fieldName: localize.getText("name"),
              });
            }
            if (!values.phoneNumber) {
              errors.phoneNumber = localize.getText(
                "generalRequiredFieldValidation",
                { fieldName: localize.getText("phoneNumber") }
              );
            }
            if (!values.dateOfBirth) {
              errors.dateOfBirth = localize.getText(
                "generalRequiredFieldValidation",
                { fieldName: localize.getText("dateOfBirth") }
              );
            }
            if (!values.gender) {
              errors.gender = localize.getText(
                "generalRequiredFieldValidation",
                { fieldName: localize.getText("gender") }
              );
            }
            if (!values.address) {
              errors.address = localize.getText(
                "generalRequiredFieldValidation",
                { fieldName: localize.getText("address") }
              );
            }

            // if (!values.identificationNumber) {
            //   errors.identificationNumber = localize.getText(
            //     "generalRequiredFieldValidation",
            //     {
            //       fieldName:
            //         values.identificationType === "KTP"
            //           ? localize.getText("numberKtp")
            //           : localize.getText("numberPassport"),
            //     }
            //   );
            // }
            if (
              values.identificationType === "KTP" &&
              values.identificationNumber &&
              values.identificationNumber.length !== 16
            ) {
              errors.identificationNumber = localize.getText(
                "notValid16Length",
                {
                  fieldName: localize.getText("numberKtp"),
                }
              );
            }
            if (!values.identificationType) {
              errors.identificationType = localize.getText(
                "generalRequiredFieldValidation",
                { fieldName: localize.getText("identity") }
              );
            }
            if (!values.medicalRecordNumber) {
              errors.medicalRecordNumber = localize.getText(
                "generalRequiredFieldValidation",
                { fieldName: localize.getText("medicalRecord") }
              );
            }

            return errors;
          }}
        >
          {({ handleSubmit, values }) => (
            <>
              <form className={styles.wrapperForm}>
                {(isEdit || isDetail) && dataForm?.satuSehatPatientId && (
                  <InputField
                    label={
                      localize.getText("satuSehat") +
                      " - " +
                      localize.getText("patientId")
                    }
                    value={dataForm?.satuSehatPatientId}
                    disabled={isDetail}
                  />
                )}
                <Field name="name">
                  {({ input, meta }) => (
                    <InputField
                      label={localize.getText("name")}
                      placeholder={localize.getText("placeholderField", {
                        fieldName: localize.getText("name"),
                      })}
                      error={meta.touched && meta.error}
                      onChange={(value) => handleChangeForm("name", value)}
                      onBlur={input.onBlur}
                      value={dataPatient.name}
                      disabled={isDetail}
                    />
                  )}
                </Field>
                <Field name="phoneNumber">
                  {({ input, meta }) => (
                    <InputField
                      label={localize.getText("phoneNumber")}
                      placeholder={localize.getText("placeholderField", {
                        fieldName: localize.getText("phoneNumber"),
                      })}
                      type="number"
                      error={meta.touched && meta.error}
                      onChange={(value) =>
                        handleChangeForm("phoneNumber", value)
                      }
                      onBlur={input.onBlur}
                      value={dataPatient.phoneNumber}
                      disabled={isDetail}
                    />
                  )}
                </Field>

                <Field name="dateOfBirth">
                  {({ input, meta }) => (
                    <InputField
                      label={localize.getText("dateOfBirth")}
                      placeholder={localize.getText("placeholderField", {
                        fieldName: localize.getText("dateOfBirth"),
                      })}
                      type="date"
                      error={meta.touched && meta.error}
                      onChange={(value) =>
                        handleChangeForm("dateOfBirth", value)
                      }
                      onBlur={input.onBlur}
                      value={dataPatient.dateOfBirth}
                      disabled={isDetail}
                    />
                  )}
                </Field>
                <Field name="gender">
                  {({ input, meta }) => (
                    <RadioField
                      label={localize.getText("gender")}
                      datas={Gender()}
                      value={dataPatient.gender}
                      disabled={isDetail}
                      onChange={(value) => handleChangeForm("gender", value)}
                      onBlur={input.onBlur}
                      error={meta.touched && meta.error}
                    />
                  )}
                </Field>
                <Field name="address">
                  {({ input, meta }) => (
                    <InputField
                      label={localize.getText("address")}
                      placeholder={localize.getText("placeholderField", {
                        fieldName: localize.getText("address"),
                      })}
                      type="textarea"
                      error={meta.touched && meta.error}
                      onChange={(value) => handleChangeForm("address", value)}
                      onBlur={input.onBlur}
                      value={dataPatient.address}
                      disabled={isDetail}
                    />
                  )}
                </Field>
                <div>
                  <Field name="identificationType">
                    {({ input, meta }) => (
                      <RadioField
                        label={localize.getText("identity")}
                        datas={TypeIdentity()}
                        value={dataPatient.identificationType}
                        disabled={isDetail}
                        onChange={(value) =>
                          handleChangeForm("identificationType", value)
                        }
                        onBlur={input.onBlur}
                        error={meta.touched && meta.error}
                      />
                    )}
                  </Field>
                  <Field name="identificationNumber">
                    {({ input, meta }) => (
                      <InputField
                        placeholder={localize.getText("placeholderField", {
                          fieldName:
                            values.identificationType === "KTP"
                              ? localize.getText("numberKtp")
                              : localize.getText("numberPassport"),
                        })}
                        error={meta.touched && meta.error}
                        onChange={(value) =>
                          handleChangeForm("identificationNumber", value)
                        }
                        onBlur={input.onBlur}
                        value={dataPatient.identificationNumber}
                        disabled={isDetail}
                      />
                    )}
                  </Field>
                </div>
                <Field name="medicalRecordNumber">
                  {({ input, meta }) => (
                    <InputField
                      label={localize.getText("medicalRecord")}
                      placeholder={localize.getText("placeholderField", {
                        fieldName: localize.getText("medicalRecord"),
                      })}
                      error={meta.touched && meta.error}
                      onChange={(value) =>
                        handleChangeForm("medicalRecordNumber", value)
                      }
                      onBlur={input.onBlur}
                      value={dataPatient.medicalRecordNumber}
                      disabled={isDetail}
                    />
                  )}
                </Field>
                <Field name="allergies">
                  {({ input, meta }) => (
                    <AsyncSelect
                      label={localize.getText("alergies")}
                      placeholder="Select Alergies..."
                      options={dataPatient.allergies}
                      isMulti
                      isCreatable
                      error={meta.touched && meta.error}
                      onChange={(value) => handleChangeForm("allergies", value)}
                      onBlur={input.onBlur}
                      disabled={isDetail}
                      value={dataPatient.allergies}
                    />
                  )}
                </Field>
                <Field name="remark">
                  {({ input, meta }) => (
                    <InputField
                      label={localize.getText("remark")}
                      placeholder={localize.getText("placeholderField", {
                        fieldName: localize.getText("remark"),
                      })}
                      type="textarea"
                      error={meta.touched && meta.error}
                      onChange={(value) => handleChangeForm("remark", value)}
                      onBlur={input.onBlur}
                      value={dataPatient.remark}
                      disabled={isDetail}
                    />
                  )}
                </Field>
                {!isDetail && (
                  <Button
                    label="Save"
                    style={{ width: 180, marginTop: 42 }}
                    onClick={handleSubmit}
                    type="submit"
                    loading={isLoadingSubmit}
                    disabled={isDetail}
                  />
                )}
              </form>
            </>
          )}
        </Form>
      </div>
    </div>
  );
}
