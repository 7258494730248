import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Button from "../../components/Button";
import styles from "./Home.module.css";
import { useAuth } from "../../../context/AuthProvider";
import Navbar from "../../layouts/Home/Navbar";
import Footer from "../../layouts/Home/Footer";

export const TitleSection = ({ title, description }) => {
  return (
    <div className={styles.wrapperTitleSection}>
      <p className={styles.titleSection}>{title}</p>
      <p className={styles.titleSectionDesc}>{description}</p>
    </div>
  );
};

export const WhyContentItem = ({ icon, title, options = [] }) => {
  return (
    <div className={styles.whyContentItem}>
      <img src={icon} className={styles.whyIconContent} alt="" />
      <p className={styles.whyTitleContent}>{title}</p>
      <div className={styles.whyOptionWrapper}>
        {options.map((item) => (
          <div className={styles.whyOptionItem}>
            <i className="far fa-check-circle"></i>
            <p>{item}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default function Home() {
  const { isAuthenticated } = useAuth();

  const dataLink = [
    { label: "Untuk Klinik", url: "#home", isActive: true },
    ...(!isAuthenticated
      ? [
          {
            label: "Untuk Pasien",
            url: "/pasien",
            isLink: true,
          },
        ]
      : []),
    { label: "Tentang Kami", url: "#about" },
    { label: "Bergabung", url: "#footer" },
  ];

  useEffect(() => {
    if (window.location.pathname.split("/").includes("dashboard")) {
      window.history.pushState({}, "", "/");
    }
  }, []);

  return (
    <div>
      <Navbar dataLink={dataLink} isAction />

      <div className={styles.heroWrapper} id="home">
        <div className={styles.heroContent}>
          <div className={styles.heroInfo}>
            <p className={styles.heroTitle}>
              Aplikasi klinik yang mudah, lengkap, dan terintegrasi
            </p>
            <p className={styles.heroDesc}>
              Sistem Klinik membangun sistem end-to-end terintegrasi untuk
              mengoptimalkan operasional pelayanan kesehatan dan memberikan
              akses kesehatan yang lebih baik kepada seluruh karyawan
            </p>
            <div className={styles.heroRegister}>
              <p className={styles.registerFeature}>
                Apa yang kami sediakan{" "}
                <i className="fas fa-long-arrow-alt-right"></i>
              </p>
              {!isAuthenticated && (
                <Link to="/register">
                  <Button
                    label="Daftar"
                    className={styles.heroRegisterButton}
                  />
                </Link>
              )}
            </div>
            <div className={styles.wrapperIntegration}>
              <p className={styles.labelIntegration}>Terintegrasi Dengan</p>
              <img
                src="/assets/images/logo-satusehat.png"
                style={{ width: 170 }}
                alt=""
              />
            </div>
          </div>
          <img src="/assets/svg/hero-image.svg" className="img-fluid" alt="" />
        </div>
      </div>

      {/* <div className={styles.collaborateWrapper}>
        <p className={styles.collaborateTitle}>
          Kami telah bekerja sama dengan 50+ klinik dan client perorangan
        </p>
        <div className={styles.collaborateLogo}>
          <img
            src="/assets/images/dummy-logo/logo-1.png"
            className="img-fluid"
            alt="dummy-logo1"
          />
          <img
            src="/assets/images/dummy-logo/logo-2.png"
            className="img-fluid"
            alt="dummy-logo2"
          />
          <img
            src="/assets/images/dummy-logo/logo-3.png"
            className="img-fluid"
            alt="dummy-logo3"
          />
          <img
            src="/assets/images/dummy-logo/logo-4.png"
            className="img-fluid"
            alt="dummy-logo4"
          />
          <img
            src="/assets/images/dummy-logo/logo-5.png"
            className="img-fluid"
            alt="dummy-logo5"
          />
        </div>
      </div> */}

      <div className={styles.whyWrapper} id="about">
        <TitleSection
          title="mengapa sistem klinik"
          description="Melayani Pasien Lebih Cepat dan Mudah"
        />
        <div className={styles.whyContentWrapper}>
          <WhyContentItem
            icon="/assets/svg/icon-file.svg"
            title="Aksesibilitas yang lebih baik"
            options={[
              "Sistem klinik berbasis cloude",
              "Informasi pasien dapat diakses kapanpun",
              "Update data pasien sangat mudah dimana saja",
            ]}
          />
          <WhyContentItem
            icon="/assets/svg/icon-computer.svg"
            title="Kemudahan penggunaan"
            options={[
              "Sistem dirancang sangat user-friendly",
              "Mudah digunakan oleh staff dan administrasi",
              "Fitur sistem update setiap minggu",
            ]}
          />
        </div>
      </div>

      <div className={styles.serviceWrapper}>
        <img
          src="/assets/svg/service-image.svg"
          className="img-fluid"
          alt="service"
        />
        <div className={styles.serviceContentWrapper}>
          <TitleSection
            title="layanan kami"
            description="Memantau dan Meningkatkan Performa Melalui Data"
          />
          <div className={styles.serviceItemWrapper}>
            <div className={styles.serviceOption}>
              <i className="fas fa-circle"></i>
              <p>Meningkatkan efisiensi operasional melalui analisis data.</p>
            </div>
            <div className={styles.serviceOption}>
              <i className="fas fa-circle"></i>
              <p>
                Pencarian riwayat kesehatan, penyakit, dan pengobatan pasien
                secara cepat dan aman.
              </p>
            </div>
            <div className={styles.serviceOption}>
              <i className="fas fa-circle"></i>
              <p>
                Perlindungan data pasien dengan sistem keamanan yang canggih.
              </p>
            </div>
          </div>
        </div>
      </div>

      <Footer dataLink={dataLink} isJoin />
    </div>
  );
}
