import React, { useEffect, useState } from "react";
import { Form, Field } from "react-final-form";
import Button from "../Button";
import styles from "./ModalStartVisit.module.css";
import { useLocalization } from "../../../context/LocalizeContextProvider";
import { useHome } from "../../../context/HomeProvider";
import useLocation from "../../../hooks/SatuSehat/useLocation";
import AsyncSelect from "../AsyncSelect";
import InputField from "../InputField";
import Modal from "../Modal";
import Spinner from "../Spinner";

export default function ModalStartVisit({
  patientId = null,
  doctorId = null,
  identificationNumber = null,
  bookingId = null,
  handleHideModal,
  handleConfirmModal,
  isLoading = false,
  isReservation = false,
  show = false,
}) {
  const localize = useLocalization();
  const { dataDoctors } = useHome();
  const { dataLocations } = useLocation();
  const [selectedDoctor, setSelectedDoctor] = useState(null);

  useEffect(() => {
    if (doctorId) {
      const selected = dataDoctors.find((item) => item.id === doctorId);
      if (selected) {
        setSelectedDoctor({
          value: selected.id,
          label: selected.name,
        });
      }
    }
  }, [doctorId, dataDoctors]);

  const handleSave = (value) => {
    const data = {
      staffId: value.doctor.value,
      locationId: value?.location?.value || null,
      ...value,
    };
    handleConfirmModal(data);
  };

  return (
    <Modal visible={show} centered handleHideModal={handleHideModal}>
      <Spinner loading={isLoading}>
        <div className={styles.wrapper}>
          <div>
            <h3>{localize.getText("confirmation")}</h3>
            <p className={styles.notesConfirm}>
              {localize.getText("confirmationStartVisit")}
            </p>
          </div>
          <Form
            onSubmit={handleSave}
            initialValues={{
              patientId,
              bookingId,
              identificationNumber,
              doctor: selectedDoctor,
            }}
            validate={(values) => {
              const errors = {};
              if (!values.doctor) {
                errors.doctor = localize.getText(
                  "generalRequiredFieldValidation",
                  {
                    fieldName: localize.getText("doctor"),
                  }
                );
              }
              if (!values.identificationNumber) {
                errors.identificationNumber = localize.getText(
                  "generalRequiredFieldValidation",
                  {
                    fieldName: localize.getText("numberKtp"),
                  }
                );
              }
              if (
                values.identificationNumber &&
                values.identificationNumber.length !== 16
              ) {
                errors.identificationNumber = localize.getText(
                  "notValid16Length",
                  {
                    fieldName: localize.getText("numberKtp"),
                  }
                );
              }

              return errors;
            }}
          >
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit} className={styles.wrapper}>
                <Field name="location">
                  {({ input, meta }) => (
                    <AsyncSelect
                      label={localize.getText("location")}
                      options={dataLocations}
                      placeholder={localize.getText("placeholderField", {
                        fieldName: localize.getText("location"),
                      })}
                      error={meta.touched && meta.error}
                      onChange={(value) => input.onChange(value)}
                      onBlur={input.onBlur}
                      value={input.value}
                      labelKey="name"
                      valueKey="id"
                    />
                  )}
                </Field>
                <Field name="identificationNumber">
                  {({ input, meta }) => (
                    <InputField
                      label={localize.getText("numberKtp")}
                      placeholder={localize.getText("placeholderField", {
                        fieldName: localize.getText("numberKtp"),
                      })}
                      error={meta.touched && meta.error}
                      onChange={(value) => input.onChange(value)}
                      onBlur={input.onBlur}
                      value={input.value}
                    />
                  )}
                </Field>
                <Field name="doctor">
                  {({ input, meta }) => (
                    <AsyncSelect
                      label={localize.getText("doctor")}
                      options={dataDoctors}
                      placeholder={localize.getText("placeholderField", {
                        fieldName: localize.getText("doctor"),
                      })}
                      error={meta.touched && meta.error}
                      onChange={(value) => input.onChange(value)}
                      onBlur={input.onBlur}
                      value={input.value}
                      disabled={isReservation}
                      labelKey="name"
                      valueKey="id"
                    />
                  )}
                </Field>
                <div className={styles.actionWrapper}>
                  <Button
                    label={localize.getText("cancel")}
                    variant="danger"
                    onClick={handleHideModal}
                    size="sm"
                  />
                  <Button
                    label={localize.getText("save")}
                    loading={isLoading}
                    size="sm"
                    type="submit"
                  />
                </div>
              </form>
            )}
          </Form>
        </div>
      </Spinner>
    </Modal>
  );
}
