import React from "react";
import ListReportVisitComponent from "../../components/ListReportVisit";
import useReportVisit from "../../../../../hooks/useReportVisit";
import { useHome } from "../../../../../context/HomeProvider";
import { useAuth } from "../../../../../context/AuthProvider";
import { roleUser } from "../../../../../utils/constants";

export default function ListdReportVisit() {
  const { dataDoctors, isLoadingDoctor } = useHome();
  const { role } = useAuth();
  const isDoctor =
    role === roleUser.ROLE_DOCTOR || role === roleUser.ROLE_BEAUTICIAN;

  const {
    dataReportVisit,
    handleChangeFilter,
    handleChangeSizePerPage,
    handleClickFilter,
    handlePagination,
    handleClearFilter,
    handleExportReport,
    isLoading,
    totalEntries,
    totalPage,
    filterReport,
    isLoadingExport,
  } = useReportVisit();

  return (
    <ListReportVisitComponent
      dataReport={dataReportVisit}
      isLoading={isLoading}
      totalPage={totalPage}
      totalEntries={totalEntries}
      activePage={filterReport?.page}
      handlePagination={handlePagination}
      filterReport={filterReport}
      handleChangeFilter={handleChangeFilter}
      handleClickFilter={handleClickFilter}
      handleClearFilter={handleClearFilter}
      handleChangeSizePerPage={handleChangeSizePerPage}
      handleExportReport={handleExportReport}
      isLoadingExport={isLoadingExport}
      dataDoctors={dataDoctors}
      isLoadingDoctor={isLoadingDoctor}
      isDoctor={isDoctor}
    />
  );
}
