// usePatientReservationValidation.js
import { useLocalization } from "../../context/LocalizeContextProvider";
import { validEmail } from "../constants";

const usePatientReservationValidation = () => {
  const localize = useLocalization();

  const validate = (values) => {
    const errors = {};

    // if (!values.identificationNumber) {
    //   errors.identificationNumber = localize.getText(
    //     "generalRequiredFieldValidation",
    //     {
    //       fieldName: localize.getText("identityNumber"),
    //     }
    //   );
    // }

    if (!values.name) {
      errors.name = localize.getText("generalRequiredFieldValidation", {
        fieldName: localize.getText("name"),
      });
    }

    // if (!values.email) {
    //   errors.email = localize.getText("generalRequiredFieldValidation", {
    //     fieldName: localize.getText("email"),
    //   });
    // }

    if (values.email && !values.email.match(validEmail)) {
      errors.email = localize.getText("notValidField", {
        fieldName: localize.getText("email"),
      });
    }

    if (!values.gender) {
      errors.gender = localize.getText("generalRequiredFieldValidation", {
        fieldName: localize.getText("gender"),
      });
    }

    if (!values.dateOfBirth) {
      errors.dateOfBirth = localize.getText("generalRequiredFieldValidation", {
        fieldName: localize.getText("dateOfBirth"),
      });
    }

    if (!values.phoneNumber) {
      errors.phoneNumber = localize.getText("generalRequiredFieldValidation", {
        fieldName: localize.getText("phoneNumber"),
      });
    }

    if (!values.address) {
      errors.address = localize.getText("generalRequiredFieldValidation", {
        fieldName: localize.getText("address"),
      });
    }

    // if (!values.identificationNumber) {
    //   errors.identificationNumber = localize.getText(
    //     "generalRequiredFieldValidation",
    //     {
    //       fieldName:
    //         values.identificationType === "KTP"
    //           ? localize.getText("numberKtp")
    //           : localize.getText("numberPassport"),
    //     }
    //   );
    // }

    if (
      values.identificationType === "KTP" &&
      values.identificationNumber &&
      values.identificationNumber.length !== 16
    ) {
      errors.identificationNumber = localize.getText("notValid16Length", {
        fieldName: localize.getText("numberKtp"),
      });
    }

    // if (!values.identificationType) {
    //   errors.identificationType = localize.getText(
    //     "generalRequiredFieldValidation",
    //     { fieldName: localize.getText("identity") }
    //   );
    // }

    if (!values.medicalRecordNumber) {
      errors.medicalRecordNumber = localize.getText(
        "generalRequiredFieldValidation",
        { fieldName: localize.getText("medicalRecord") }
      );
    }

    return errors;
  };

  return validate;
};

export default usePatientReservationValidation;
